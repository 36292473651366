export default function() {

  const callback = () => {
    document.body.querySelectorAll('ion-button').forEach((btn) => {
      btn.classList.remove('button')
    })
  }

  const observer = new MutationObserver(callback);
  observer.observe(document.body, { childList: true, subtree: true });

}
